export const SET_PAGES = 'set-pages';
export const SET_PAGE_DATA = 'set-page-data';
export const SET_PAGE = 'set-page';
export const SET_TRANSLATIONS = 'set-page';
export const LOADING = 'loading';
export const SET_ERROR = 'set-error';
export const SET_TRANSLATION = 'set-translation-exists';
export const SET_LANGUAGES = 'set-langs';

export default {  
    [SET_PAGES](state, {pages}) {
        state.pages = pages;
        state.hasError = false;        
    },
    [SET_PAGE_DATA](state,{page,translations}) {
        state.page = page;
        state.translations = translations;
        state.hasError = false; 
    },
    [SET_PAGE](state,page) {
        state.page = page;
        state.hasError = false; 
    },
    [LOADING](state, loading) {
        state.loading = loading;
    },
    [SET_ERROR](state) {
        state.hasError= true;    
    },
    [SET_TRANSLATION](state, lang, missing = false) {        
        if (state.translations) {
            const tl = state.translations.find(t => t.language == lang || (t.language == "" && !lang));
            if (tl) {
                tl.isMissing = !!missing;
            }
        }
    },
    [SET_LANGUAGES](state, languages) {
        state.languages = languages;
    }
}