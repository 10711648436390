import { VersionTimer } from './VersionTimer.js';

export const getResponseData = e => (e && e.response && e.response.data) ? e.response.data : undefined;
export const getResponseStatus = e => (e && e.response && e.response.status) ? e.response.status : undefined;
export const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const validateUrl = (url) => (/^(https?:\/\/[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/g.test(url));

export const toSnakeCase = s => s.replace(/(?:^|\.?)([A-Z])/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "");

export const formatDate = (date) => {
    date = (date instanceof Date) ? date : new Date(date);

    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${date.toLocaleDateString()} ${hours}:${minutes}`;
}

export const toRoundtripDate = (date) => {
    const d = (date instanceof Date) ? date : new Date(date);

    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
};

export const getErrorMessage = (e) => 
{
    const data = getResponseData(e);
    const status = getResponseStatus(e);
    
    if (status == 500 || !data) {
        return "An unknown error occurred. Please try again later.";
    }
    else if (status == 429) {
        return "An error occurred. Please try again later.";
    }
    else if (data) {
        switch (data.errorCode) {
            case 2:
                return "Too many failed attempts. Please try again later.";
            default:
                return "Reset password failed.";
        }
    }
}

export { VersionTimer };