export default {
    loading(state) {
        return state.loading;
    },    
    hasError(state) {
        return state.hasError;
    },
    products(state) {
        return state.products;
    },
    translations(state) {
        return state.translations;
    },
    product(state) {
        return state.product;
    },
    nonBonus(state) {
        return state.nonBonus;
    },
    missingRewards(state) {
        return  state.missingRewards;
    },
    bonusSkus(state) {
        return state.bonusSkus;
    },
    channels(state) {
        return state.channels;
    },    
    channel(state) {
        return state.channel;
    }    
}