import axios from 'axios';
import config from '@/appConfig';
import store from '@/store';
import { REFRESH, REFRESHED } from '@/store/modules/auth/mutation-types';
import { EXPIRED } from '@/store/modules/auth/action-types';

const client = axios.create({
    baseURL: config.BASE_URL,
    withCredentials: true
});

const refreshAccessToken = async () => { 
    store.commit(REFRESH);
    const accessToken = store.getters.token;
    const response = await axios.post('/api/auth/refresh', { accessToken }, { baseURL: config.BASE_URL, withCredentials: true });
    const data = response.data;
    store.commit(REFRESHED, { 
        token: data.accessToken, 
        tokenExpiration: data.accessTokenExpiration, 
        refreshExpiration: data.refreshTokenExpiration
    });
}; 

client.interceptors.response.use(
    (response) => {
        return response;
    }, 
    async (error) => 
    {    
        // Other error
        if (error.response?.status !== 401) {
            return new Promise((resolve, reject) => {                
                reject(error);
            });
        }    

        const refreshExpiration = store.getters.refreshExpiration;
        const isRefreshExpired = isNaN(refreshExpiration) || (refreshExpiration < new Date().getTime());

        if (error.config.url == '/api/auth/refresh' || error.response.errorCode == 3 || error.response.errorCode == 2 || isRefreshExpired) {
            store.dispatch(EXPIRED); 
            throw error;  
        }
        else 
        {
            try {
                // Maybe needs refresh
                await refreshAccessToken();
            }
            catch (e) {
                const status = e && e.response && e.response.status ? e.response.status : undefined;
                if (status == 401 || status == 403 || status == 400) {
                    store.dispatch(EXPIRED); 
                    throw error;
                }
                else {
                    throw e;
                }
            }
            
            const token = store.getters.token;
            const config = error.config;
            config.data = config.data ? JSON.parse(config.data) : config.data;
            config.headers = {'Authorization': `bearer ${token}`};

            return await client.request(config);
        }
    }
);

export default client;

