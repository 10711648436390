import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            loading: false,
            hasError: false,
            products: [],
            product: null,
            nonBonus: [],
            translations: [],
            channels: [],
            channel: null,
            bonusSkus: [],
            missingRewards: false                       
        }
    },
    mutations,
    actions,
    getters
};