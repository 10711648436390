
import { toSnakeCase } from '@/utils';

export default {
    isAuthenticated(state) {
        return !!state.token && !state.loggingOut;
    },
    // Is completely logged out.
    isLoggedOut(state) {
        return state.status == 'logged-out';
    },
    isRefreshing(state) {
        return state.status == 'refreshing';
    },
    merchantId(state) {
        return state.merchantId;
    },
    token(state) {
        return state.token;
    },
    tokenExpiration(state) {
        return state.tokenExpiration;
    },
    refreshExpiration(state) {
        return state.refreshExpiration;
    },
    status(state) {
        return state.status;
    },
    profilePicture(state) {
        return state.profilePicture;
    },
    hasProfilePicture(state) {
        return !!state.profilePicture;
    },
    sites(state) {
        return state.sites ? state.sites : [];
    },
    permissions(state) {
        if (state != null) {
            const site = state.sites.find(s => s.merchantId == state.merchantId);
            if (site != null) {
                return site.permissions.map(p => toSnakeCase(p));
            }
        }
        return [];
    },
    logoFile(state) {
        return state.merchant ? state.merchant.logoFile : null;
    },
    siteUrl(state) {
        return state.merchant ? `https://${state.merchant.siteUrl}?preview=true` : "#";
    },
    hidden(state) {
        return state.merchant ? state.merchant.isHidden : false;
    },
    username(state) {
        return state.username;
    },
    email(state) {
        return state.email
    },
    resetToken: (state) => state.resetToken,
    hasRight(state, getters) {
        return (r => getters.permissions.includes(r));
    }
}