<template>    
    <CCardBody class="d-flex flex-column">
        <CRow>
            <CCol>								
                <h1>{{ title }}</h1>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">								
                <p class="text-muted">Enter your email address below to reset your password.</p>
            </CCol>
        </CRow>
        
        <CRow>
            <CCol sm="7">
                <CForm @submit.prevent="submit">
                    <CInput placeholder="Email" autocomplete="email" :disabled="loading" type="email"
                            style="height: 42px"
                            v-model="email"
                            :isValid="checkIfValid('email')"
                            :value.sync="$v.form.email.$model"
                            invalidFeedback="This is a required field and must be valid e-mail address"
                    >
                        <template #prepend-content
                            ><CIcon name="cil-user"
                        /></template>
                    </CInput>
                    <div class="d-flex flex-row">
                        <CButton color="primary" type="submit" :disabled="loading"><CSpinner v-if="loading" size="sm" class="mr-2"></CSpinner>Reset Password</CButton> 
                        <CLink v-if="!firstVisit" style="margin-left: auto" class="mr-1 align-self-center" to="login" :disabled="loading">Back to Login</CLink>   
                    </div>
                </CForm>
            </CCol>
        </CRow>
        <CRow class="justify-self-end" style="margin-top: auto;">
            <CCol>
                <CAlert :show="true" color="danger" :fade="false" :class="['mt-3', 'mb-2', errorMessage ? 'visible' : 'invisible']">{{ errorMessage }}</CAlert>
            </CCol>
        </CRow>		
    </CCardBody>
</template>

<script>
import { authAction } from '@/store/modules/auth';
import { getErrorMessage } from "@/utils";
import { validationMixin } from "vuelidate"
import { required, email } from "vuelidate/lib/validators"
import router from '@/router';

export default {
    created() {
        if (this.mail) {
            this.email = this.mail;
            this.$v.form.email.$touch();
            this.form = {
                email: this.mail
            }
        }
    },
    data() {
        return {
            form: this.getEmptyForm(),
            loading: false,
            email: "",
            errorMessage: null, 
        }
    },
    props: {
        mail: String
    },
    computed: {
        isValid () { return !this.$v.form.$invalid },
        isDirty () { return this.$v.form.$anyDirty },
        title() { return this.firstVisit ? "Welcome" : "Reset Password"; },
        firstVisit() { return !!this.mail; }
	},  
    methods: {
        async submit() {
            this.errorMessage = null;
            this.$v.form.email.$touch();

            if (!this.isValid) {
                return;
            }
            this.loading = true;

            try {
                if (this.email) {
                    await this.$store.dispatch(authAction.RESET_PASSWORD, { email: this.email });
                }
                router.replace({path: '/verify', query: { login: this.firstVisit ? false : null }});
            }
            catch (e) {
                this.handleError(e);
            }

            this.loading = false;
        },
        getEmptyForm () {
            return {
                email: "",
            }
        },
        checkIfValid(fieldName) {
            const field = this.$v.form[fieldName];
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '') ? null : false;  
        },
        handleError(e) {
			this.errorMessage = getErrorMessage(e);
            if (!this.errorMessage) this.errorMessage = "An unknown error occurred. Please try again later.";
        }
    },
    mixins: [validationMixin],
    validations: {
        form: {
            email: {
                required,
                email
            }
        }
    }
}
</script>

