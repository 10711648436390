import { general } from '@/api';
import { LOADING, SET_DATA } from './mutations';

export const LOAD = 'load';
export const UPDATE = 'update';

export const actionNames = Object.freeze({
    LOAD: 'general/' + LOAD,
    UPDATE: 'general/' + UPDATE
});

export default { 
    async [LOAD]({rootGetters,commit}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);
        // commit(SET_DATA, null);
        
        let response = null;
        try {
            response = await general.load({merchantId});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }    
        commit(LOADING, false);
        
        const data = response.data;

        return commit(SET_DATA, data);  
    },

    async [UPDATE]({rootGetters,commit}, {automation}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);

        let response = null;
        try {
            response = await general.update({merchantId, automation});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }    
        commit(LOADING, false);

        const data = response.data;

        return commit(SET_DATA, data);  
    }
}

