export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PRODUCT_IMAGE = 'SET_PRODUCT_IMAGE';
export const SET_ERROR = 'SET_ERROR';
export const SETTINGS = 'SETTINGS';
export const LOADING = 'LOADING';
export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_CHANNEL = 'SET_CHANNEL';
export const PRODUCT_ACTIVE = 'SET_PRODUCT_ACTIVE';
export const BONUS_STATUS= 'BONUS_STATUS';

export default {
    [SET_PRODUCTS](state, {products, channel}) {
        state.products = products;
        state.channel = channel;
        state.hasError = false;        
    },
    [SET_PRODUCT](state, {product}) {
        state.product = product;
    },
    [SET_PRODUCT_IMAGE](state, {sku, imageFile}) {
        if (state.product && (state.product.sku == sku))
        {
            state.product.imageFile = imageFile;
        }
    },
    [PRODUCT_ACTIVE](state, {sku,channel,isActive}) 
    {
        if (state.product && (state.product.sku == sku))
        {
            if (!channel) {
                state.product.isActive = isActive;
            }
        }
    },
    [BONUS_STATUS](state, {sku,isAvailable}) 
    {
        if (state.product && (state.product.sku == sku))
        {
            state.product.isAvailable = isAvailable;
        }
    },
    [SET_CHANNELS](state, {channels}) {
        state.channels = channels;
        state.hasError = false;   
    },
    [SET_CHANNEL](state, {channel}) {
        state.channel = channel;
        state.hasError = false;  
    },
    [LOADING](state, loading) {
        state.loading = loading;
    },
    [SET_ERROR](state) {
        state.hasError= true;    
    },
    [SETTINGS](state, {channels, translations, nonBonus, missingRewards, bonusSkus}) {
        state.channels = channels;
        state.translations = translations;
        state.nonBonus = nonBonus;
        state.missingRewards = missingRewards;
        state.bonusSkus = bonusSkus;
    }
}