import client from './client';
import config from '@/appConfig';
import axios from 'axios';
import store from '@/store';
import { PUSH_ERROR } from '@/store'

function getHeaders() {
    const token = window.store.getters.token;
    return {headers: {'Authorization': `bearer ${token}`}};
}

export const authorization = {
    async login({ email, password, rememberMe }) {
       const response = await axios.post('/api/auth/login', {email, password, rememberMe}, { baseURL: config.BASE_URL, withCredentials: true });
       return response.data;
    },
    async loginGoogle(googleArgs) {
        const response = await axios.post('/api/auth/google', googleArgs, { baseURL: config.BASE_URL, withCredentials: true });
        return response.data;
    },
    async logout() {
        const response = await client.post('/api/auth/logout', getHeaders());
        return response.data;
    },
    async refresh({accessToken}) {
        const response = await client.post('/api/auth/refresh', {accessToken}, { baseURL: config.BASE_URL, withCredentials: true });
        return response.data;
    },
    async sites({token}) {
        const response = await client.get('/api/auth/sites', {headers: {'Authorization': `bearer ${token}`}});
        return response.data;
    },
    async reset({email}) {
        const response = await client.post('/api/auth/reset', {email}, {withCredentials: false});
        return response.data;
    },
    async verify({ email, token }) {
        const response = await client.post('/api/auth/verify', {email,token}, {withCredentials: false});
        return response.data;
    },
    async password({ email, token, newPassword }) {
        const response = await client.post('/api/auth/password', {email,token,newPassword}, {withCredentials: false});
        return response.data;
    }
};

export const merchants = {
    async base({merchantId, token}) {
        const response = await client.get(`/api/merchants/${merchantId}/base`, {headers: {'Authorization': `bearer ${token}`}});
        return response.data;
    }
};

export const products = {
    async listChannel({merchantId,channel}) {
        try {
            const response = await client.get(`/api/products/${merchantId}/channel/${channel}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load products list for channel "${channel}".`});
            throw e;
        }
    },
    async listTranslation({merchantId,translation}) {
        try {
            const response = await client.get(`/api/products/${merchantId}/translation/${translation}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load products list for translation "${translation}".`});
            throw e;
        }
    },
    async listRewards({merchantId}) {
        try {
            const response = await client.get(`/api/products/${merchantId}/rewards`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load products list.`});
            throw e;
        }
    },
    async list({merchantId}) {
        try {
            const response = await client.get(`/api/products/${merchantId}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load products.`});
            throw e;
        }
    },
    async details({merchantId, sku}) {
        try {
            const response = await client.get(`/api/products/${merchantId}/details/${sku}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load product '${sku}'.`});
            throw e;
        }
    },
    async setActive({merchantId, sku, channel, isActive}) {
        const payload = { channel, isActive };
        try {
            const response = await client.put(`/api/products/${merchantId}/enabled/${sku}`, payload, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load product '${sku}'.`});
            throw e;
        }
    },
    async update({merchantId,sku,translations,removeRewardSKU,addRewardSku}) {
        const payload = { translations,removeRewardSKU,addRewardSku };
        try {
            const response = await client.put(`/api/products/${merchantId}/details/${sku}`, payload, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to update product '${sku}'.`});
            throw e;
        }
    }
}

export const bonuses = {
    async list({merchantId}) {
        try {
            const response = await client.get(`/api/bonuses/${merchantId}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load bonuses.`});
            throw e;
        }
    },
    async listTranslation({merchantId,translation}) {
        try {
            const response = await client.get(`/api/bonuses/${merchantId}/translation/${translation}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load bonus list for translation "${translation}".`});
            throw e;
        }
    },
    async details({merchantId, sku}) {
        try {
            const response = await client.get(`/api/bonuses/${merchantId}/details/${sku}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load bonus product '${sku}'.`});
            throw e;
        }
    },
    async setAvailable({merchantId, sku, isAvailable}) {
        const payload = { isAvailable };
        try {
            const response = await client.put(`/api/bonuses/${merchantId}/status/${sku}`, payload, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to update bonus '${sku}'.`});
            throw e;
        }
    },
    async setTranslations({merchantId, sku, translations}) {
        const payload = { translations };
        try {
            const response = await client.put(`/api/bonuses/${merchantId}/details/${sku}`, payload, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to update bonus '${sku}'.`});
            throw e;
        }
    },

    async create({merchantId, sku, bonus}) {
        try {
            const url = `/api/bonuses/${merchantId}/create/${sku}`;
            const response = await client.post(url, bonus, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Could not create bonus.`});
            throw e;
        }
    },
    async remove({merchantId, sku}) {
        try {
            const url = `/api/bonuses/${merchantId}/remove/${sku}`;
            const response = await client.delete(url, {}, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Could not remove bonus.`});
            throw e;
        }
    }
}

export const channels = {
    async list({merchantId}) {
        try {
            const response = await client.get(`/api/channels/${merchantId}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load channels.`});
            throw e;
        }
    },
    async details({merchantId, channel}) {
        try {
            const response = await client.get(`/api/channels/${merchantId}/${channel}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load channel '${channel}'.`});
            throw e;
        }
    },
    async listUploadChannels({merchantId}) {
        try {
            const response = await client.get(`/api/upload/channels/${merchantId}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load channel list.`});
            throw e;
        }
    },
    async update({merchantId, channel, data}) {
        try {
            const url = `/api/channels/${merchantId}/${channel}`;
            const meta = { baseURL: config.BASE_URL, withCredentials: true, ...getHeaders() };
            const response = await client.put(url, data, meta);
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to update channel '${channel}' .`});
            throw e;
        }
    }
}

export const pages = {
    async list({merchantId}) {
        try {
            const response = await client.get(`/api/pages/${merchantId}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load pages.`});
            throw e;
        }
    },
    async details({merchantId, name, language}) {
        try {
            const response = await client.get(`/api/pages/${merchantId}/${name}/${language}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load page '${name}' for language '${language}'.`});
            throw e;
        }
    },
    async save({merchantId, name, language, title, content, kind}) {
        try {
            const url = `/api/pages/${merchantId}/${name}/${language}`;
            const payload = {title, content, kind};
            const meta = { baseURL: config.BASE_URL, withCredentials: true, ...getHeaders() };
            const response = await client.put(url, payload, meta);
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to save page '${name}' for language '${language}'.`});
            throw e;
        }
    },
    async delete({merchantId, name}) {
        try {
            const url = `/api/pages/${merchantId}/${name}`;
            const meta = { baseURL: config.BASE_URL, withCredentials: true, ...getHeaders() };
            const response = await client.delete(url, {}, meta);
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to delete page '${name}'!`});
            throw e;
        }
    },
    async create({merchantId, name, kind}) {
        try {
            const url = `/api/pages/${merchantId}/${name}`;
            const meta = { baseURL: config.BASE_URL, withCredentials: true, ...getHeaders() };
            const response = await client.post(url, {contentKind:kind}, meta);
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to create page '${name}'!`});
            throw e;
        }
    },
    async removeTranslation({merchantId, name, language}) {
        try {
            const url = `/api/pages/${merchantId}/${name}/remove/${language}`;
            const meta = { baseURL: config.BASE_URL, withCredentials: true, ...getHeaders() };
            const response = await client.put(url, {}, meta);
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to remove language '${language}'!`});
            throw e;
        }
    }
}

export const links = {
    async list({merchantId}) {
        try {
            const response = await client.get(`/api/links/${merchantId}`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load links.`});
            throw e;
        }
    },
    async move({merchantId, from, to}) {
        try {
            const url = `/api/links/${merchantId}/${from}?to=${to}`;
            const response = await client.put(url, {}, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to move link.`});
            throw e;
        }
    },
    async delete({merchantId, index}) {
        try {
            const url = `/api/links/${merchantId}/${index}`;
            const response = await client.delete(url, {}, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to delete link.`});
            throw e;
        }
    },
    async save({merchantId, index, link}) {
        try {
            const url = `/api/links/${merchantId}/${index}`;
            const response = await client.post(url, link, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to save link.`});
            throw e;
        }
    },
}

export const general = {
    async load({merchantId}) {
        try {
            const url = `/api/general/${merchantId}`;
            const response = await client.get(url, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load general settings.`});
            throw e;
        }
    },
    async update({merchantId, automation}) {
        try {
            const url = `/api/general/${merchantId}`;
            const response = await client.put(url, { automation }, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to update settings.`});
            throw e;
        }
    }
}

export const shipments = {
    async find({merchantId, filter}) {
        try {
            const url = `/api/shipments/${merchantId}`;
            const response = await client.post(url, filter, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load shipments.`});
            throw e;
        }
    },
    async export({merchantId, filter})
    {
        try {
            const url = `/api/shipments/${merchantId}/export`;
            const response = await client.post(url, filter, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to export shipments.`});
            throw e;
        }
    },
    async loadSettings({merchantId}) {
        try {
            const response = await client.get(`/api/shipments/${merchantId}/settings`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load shipment settings.`});
            throw e;
        }
    },
    async markShipped({merchantId, idList, shipped}) {
        const data = {
            ids: idList,
            shipped
        }
        try {
            const response = await client.put(`/api/shipments/${merchantId}/ship`, data, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to update shipment status.`});
            throw e;
        }
    },
    async setComment({merchantId,id,comment}) {
        try {
            const response = await client.put(`/api/shipments/${merchantId}/comment/${id}`, {comment}, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to set comment status.`});
            throw e;
        }
    }
}

export const feedbacks = {
    async loadSettings({merchantId}) {
        try {
            const response = await client.get(`/api/feedbacks/${merchantId}/settings`, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load feedback settings.`});
            throw e;
        }
    },
    async find({merchantId, filter}) {
        try {
            const url = `/api/feedbacks/${merchantId}`;
            const response = await client.post(url, filter, getHeaders());
            return response;
        }
        catch (e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to load feedbacks.`});
            throw e;
        }
    },
    async conversion({merchantId,feedbackIds,conversion}) {
        try {
            const url = `/api/feedbacks/${merchantId}/conversion`;
            const response = await client.post(url, {feedbackIds,conversion}, getHeaders());
            return response;
        }
        catch(e) {
            store.commit(PUSH_ERROR, {message: `An error occured. Failed to update conversion.`});
            throw e;
        }
    }
}

export const getUploadUrl = (merchantId) => config.BASE_URL + `/api/products/${merchantId}/upload`;

export const getProductImageUploadUrl = (merchantId, sku) => config.BASE_URL + `/api/products/${merchantId}/upload/${sku}/image`;

export const getBonusImageUploadUrl = (merchantId, sku) => config.BASE_URL + `/api/bonuses/${merchantId}/upload/${sku}/image`;

export const getContentUploadUrl = (merchantId, fileType) => config.BASE_URL + `/api/settings/${merchantId}/upload/${fileType}`;
