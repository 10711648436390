/* eslint-disable */
import { authorization, merchants } from '@/api';
import { LOGGED_IN, LOGGING_IN, ERROR, LOGGING_OUT, LOGGED_OUT, CHECK_ACCESS, RESTORE, REFRESHED, REFRESH as M_REFRESH, SET_MERCHANT, SET_HTML_CONTENT, SET_RESET, CLEAR_RESET  } from './mutation-types';
import { LOGIN, LOGIN_GOOGLE, LOGOUT, REFRESH, EXPIRED, ACCESS, INIT, LOAD_MERCHANT, CHECK_VERSION, RESET_PASSWORD, VERIFY, SET_PASSWORD  } from './action-types';
import { PUSH_ERROR } from '@/store'
import router from '@/router';

export default {
    async [LOGIN](context, {email, password, rememberMe}) {
        context.commit(LOGGING_IN);
        try {
            const data = await authorization.login({
                email,
                password,
                rememberMe
            });

            context.commit(LOGGED_IN, {
                token: data.accessToken, 
                tokenExpiration: data.accessTokenExpiration, 
                refreshExpiration: data.refreshTokenExpiration,
                sites: data.sites,
                profilePicture: data.profilePicture,
                username: data.username
            });
        }
        catch (e) {
            context.commit(ERROR);
            throw e;
        }
        return true;
    },
    async [LOGIN_GOOGLE](context, googleArgs) {
        try {
            const data = await authorization.loginGoogle(googleArgs);
            context.commit(LOGGED_IN, {
                token: data.accessToken, 
                tokenExpiration: data.accessTokenExpiration, 
                refreshExpiration: data.refreshTokenExpiration,
                sites: data.sites,
                profilePicture: data.profilePicture,
                username: data.username
            });
        }
        catch (e) {
            context.commit(ERROR);
            throw e;
        }

        return true;
    },
    async [LOGOUT](context) {
        context.commit(LOGGING_OUT);
        try {
            await authorization.logout();
        }
        catch { }

        if (!context.getters.isLoggedOut)
        {
            context.commit(LOGGED_OUT);
            if (router.currentRoute.name != 'Login') {
                router.push('/login');
            }
        }        
    },    
    async [REFRESH](context) {
        context.commit(M_REFRESH); 
        let responseData = null;
        try {
            const accessToken = context.state.token;
            responseData = await authorization.refresh({accessToken});                
        }
        catch (e)
        {
            context.commit(ERROR);    
            throw e;
        }
        context.commit(REFRESHED, { 
            token: responseData.accessToken, 
            tokenExpiration: responseData.accessTokenExpiration, 
            refreshExpiration: responseData.refreshTokenExpiration
        });
    },
    async [ACCESS](context) {
        if (context.getters.isAuthenticated) {
            try {
                const result = await authorization.sites({token:context.getters.token});            
                context.commit(CHECK_ACCESS, {sites:result.sites});
            }
            catch (e) {            
                await context.dispatch(LOGOUT);
            }
        }
    },
    async [LOAD_MERCHANT](context) {
        if (!context.state.merchantId) {
            return;
        }
        const merchantId = context.state.merchantId;
        try {            
            context.commit(SET_MERCHANT, {merchant: null});      
            const merchant = await merchants.base({merchantId:merchantId, token:context.getters.token});   
            context.commit(SET_MERCHANT, {merchant});        
        }
        catch (e) {            
            context.commit(PUSH_ERROR, {message: `Failed to load merchant ${merchantId}`});
        }
    },
    [EXPIRED](context) {
        if (!context.getters.isLoggedOut)
        {
            context.commit(LOGGED_OUT);            
            if (router.currentRoute.name != 'Login') {
                router.push('/login');
            }
        } 
    },
    [INIT](context) {
        context.commit(RESTORE);
        if (context.getters.isAuthenticated) {
           context.dispatch(ACCESS); 
        }
    },
    async [CHECK_VERSION](context) {
        const headers = new Headers();
        headers.append('Pragma', 'no-cache');
        headers.append('Expires', -1);
        headers.append('Cache-Control', 'no-cache');
        const init = {
            method: 'GET',
            headers
        };
        const response = await fetch('index.html', init);
        const html = await response.text();        
        const prevHtml = context.state.htmlContent;
        
        context.commit(SET_HTML_CONTENT, html);

        if (html != prevHtml) {            
            if (prevHtml) {
                // Force Refresh
                try {
                    location.reload(true);
                }
                catch {
                    location.reload();
                }
            }
        }
    },
    async [RESET_PASSWORD](context, {email}) {
        try {
            const data = await authorization.reset({email});            
        }
        catch (e) {
            context.commit(ERROR);
            throw e;
        }

        context.commit(SET_RESET, { email })

        return true;
    },
    async [VERIFY](context, {code}) {
        const email = context.state.email;
        try {
            const data = await authorization.verify({email, token: code});   
            context.commit(SET_RESET, {resetToken: data.token});         
        }
        catch (e) {
            context.commit(ERROR);
            throw e;
        }

        return true;
    },
    async [SET_PASSWORD](context, {password}) {
        const email = context.state.email;
        const token = context.state.resetToken;
        try {
            const data = await authorization.password({email, token, newPassword: password });   
            context.commit(CLEAR_RESET);         
        }
        catch (e) {
            context.commit(ERROR);
            throw e;
        }

        return true;
    }
}