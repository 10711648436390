export const INIT = 'init';
export const LOGIN = 'login';
export const LOGIN_GOOGLE = 'login-google';
export const ACCESS = 'check';
export const LOGOUT = 'logout';
export const EXPIRED = 'expired';
export const REFRESH = 'refresh';
export const LOAD_MERCHANT = 'load-merchant';
export const CHECK_VERSION = 'check-version';
export const RESET_PASSWORD = 'reset-password';
export const VERIFY = 'verify';
export const SET_PASSWORD = 'set-password';

export default Object.freeze({
    LOGIN,
    LOGIN_GOOGLE,
    LOGOUT,
    REFRESH,
    ACCESS,
    EXPIRED,
    INIT,
    LOAD_MERCHANT,
    CHECK_VERSION,
    RESET_PASSWORD,
    VERIFY,
    SET_PASSWORD
});