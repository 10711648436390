export const RESTORE = 'restore';
export const LOGGING_IN = 'loggingIn';
export const LOGGED_IN = 'loggedIn';
export const REFRESH ='refresh';
export const REFRESHED ='refreshed';
export const LOGGING_OUT ='loggingOut';
export const LOGGED_OUT ='loggedOut';
export const ERROR ='error';
export const CHECK_ACCESS = 'check-access';
export const SELECT_SITE = 'select-site';
export const SET_MERCHANT = 'set-merchant';
export const SET_HTML_CONTENT = 'set-html';
export const SET_RESET = 'set-reset';
export const CLEAR_RESET = 'clear-reset';

export default {
    LOGGING_IN,
    LOGGED_IN,
    REFRESH,
    REFRESHED,
    LOGGING_OUT,
    LOGGED_OUT,
    CHECK_ACCESS,
    ERROR,
    SELECT_SITE,
    SET_MERCHANT,
    RESTORE,
    SET_HTML_CONTENT,
    SET_RESET,
    CLEAR_RESET
}