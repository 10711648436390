export default {
    loading(state) {
        return state.loading;
    },    
    hasError(state) {
        return state.hasError;
    },
    links(state) {
        return state.links;
    },
    languages(state) {
        return state.languages;
    },
    pages(state){
        return state.pages;
    }
}