import { links as api } from '@/api';
import { LOADING, RESET, SET_ALL, SET_LINKS, ERROR } from './mutations';

export const LIST_LINKS = 'list-links';
export const MOVE_LINK = 'move-link';
export const DELETE_LINK = 'delete-link';
export const SAVE_LINK = 'save-link';

export const actionNames = Object.freeze({
    LIST_LINKS: 'links/' + LIST_LINKS,
    MOVE_LINK: 'links/' + MOVE_LINK,
    DELETE_LINK: 'links/' + DELETE_LINK,
    SAVE_LINK: 'links/' + SAVE_LINK
});

export default {  
    async [LIST_LINKS]({rootGetters,commit}) 
    {
        const merchantId = rootGetters.merchantId;
        commit(RESET);
        commit(LOADING, true);

        let response = null;

        try {
            response = await api.list({merchantId});
        }
        catch (e) {
            commit(LOADING, false);
            commit(ERROR /*, e.data*/);
            throw e;
        }

        commit(LOADING, false);
        
        const data = response.data;
        const links = data.links;
        links.forEach((link,index) => {
            link.index = index;
        });
        commit(SET_ALL, { languages:data.languages, pages:data.pages, links:links });
    },
    async [MOVE_LINK]({rootGetters,commit}, {from,to})     
    {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);

        let response = null;

        try {
            response = await api.move({merchantId, from,to});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }

        commit(LOADING, false);

        const data = response.data;
        const links = data.links;

        links.forEach((link,index) => {
            link.index = index;
        });
        commit(SET_LINKS, { links:links });
    },
    async [DELETE_LINK]({rootGetters,commit}, {index})     
    {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);

        let response = null;

        try {
            response = await api.delete({merchantId, index});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }

        commit(LOADING, false);

        const data = response.data;
        const links = data.links;

        links.forEach((link,index) => {
            link.index = index;
        });
        commit(SET_LINKS, { links:links });
    },
    async [SAVE_LINK]({rootGetters,commit}, {index, link})
    {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);

        let response = null;

        try {
            response = await api.save({merchantId, index, link});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }

        commit(LOADING, false);

        const data = response.data;
        const links = data.links;

        links.forEach((link,index) => {
            link.index = index;
        });
        commit(SET_LINKS, { links:links });
    }
}