import { LOGGING_IN, LOGGED_IN, REFRESH, REFRESHED, LOGGING_OUT, LOGGED_OUT, ERROR, RESTORE, CHECK_ACCESS, SELECT_SITE, SET_MERCHANT, SET_HTML_CONTENT, SET_RESET, CLEAR_RESET } from './mutation-types';
import { EXPIRED, LOAD_MERCHANT } from './action-types';

const saveState = (state) => {    
    const payload = JSON.stringify({
        token: state.token,
        refreshExpiration: state.refreshExpiration,
        merchantId: state.merchantId,
        username: state.username,
        profilePicture: state.profilePicture,
        htmlContent: state.htmlContent
    });
    localStorage.setItem("_auth", payload);
};

const restoreState = (state) => {
    const stored = localStorage.getItem("_auth");
    try {
        const payload = JSON.parse(stored);     
        
        // Set values
        state.token = typeof payload.token == "string" ? payload.token : null;
        state.refreshExpiration = Number.isInteger(payload.refreshExpiration) ? payload.refreshExpiration : null;
        state.merchantId = typeof payload.merchantId == "string" ? payload.merchantId : null;  
        state.profilePicture = payload.profilePicture;
        state.username = payload.username;
        state.htmlContent = payload.htmlContent

        // Validate
        if (!!state.token && !!state.refreshExpiration && /*!!state.merchantId &&*/ (state.refreshExpiration > new Date().getTime())) {
            state.status = 'success';            
        }
        else {
            state.status = 'logged-out';
            state.token = null;
            state.refreshExpiration = null;
        }
        return true;
    }
    catch {
        return false;
    }
}

const clearState = () => localStorage.removeItem("_auth");

const setAutoLogout = (context, state) => {
    clearTimeout(state.timeoutId);
    const waitTime = state.refreshExpiration - new Date().getTime();
    if (waitTime > 0) {
        state.timeoutId = setTimeout(
            () => { 
                context.dispatch(EXPIRED);
            }, 
            waitTime
        );
    }
}

export default {
    [RESTORE](state) {      
        restoreState(state);
        if (state.token) {
            this.commit(SELECT_SITE, {merchantId:state.merchantId})
        }
    },
    [LOGGING_IN](state) {
        state.status = 'loading';        
    },
    [LOGGED_IN](state, { token, tokenExpiration, refreshExpiration, sites, profilePicture, username }) {   
        state.status = 'success';
        state.token = token;
        state.tokenExpiration = tokenExpiration;
        state.refreshExpiration = refreshExpiration;
        state.merchantId = sites[0].merchantId;
        state.sites = sites;
        state.profilePicture = profilePicture;
        state.username = username;

        this.commit(SELECT_SITE, {merchantId:state.merchantId})
        
        setAutoLogout(this, state);
        saveState(state);
    },
    [REFRESH](state) {
        state.status = 'refreshing';
    },
    [REFRESHED](state, { token, tokenExpiration, refreshExpiration }) {
        state.status = 'success';
        state.token = token;
        state.tokenExpiration = tokenExpiration;
        state.refreshExpiration = refreshExpiration;  

        setAutoLogout(this, state);
        saveState(state);
    },
    [LOGGING_OUT](state) {
        state.status = 'logging-out';
        state.loggingOut = true;
        clearTimeout(state.timeoutId);
        state.timeoutId = 0;
    },
    [LOGGED_OUT](state) {
        state.status = 'logged-out';
        state.token = null;
        state.tokenExpiration = 0;
        state.refreshToken = null;
        state.refreshTokenExpiration = 0;
        state.loggingOut = false;
        clearTimeout(state.timeoutId);
        clearState();
    },
    [CHECK_ACCESS](state, {sites}) {        
        state.sites = sites;
        if (!sites) {
            state.merchantId = null;
        }
        else if (sites && (state.merchantId == null || !sites.find(e => e.merchantId == state.merchantId))) {
            const merchantId = sites[0].merchantId;
            this.commit(SELECT_SITE, {merchantId: merchantId })
        }
    },
    [SELECT_SITE](state, { merchantId }) {
        state.merchantId = merchantId;        
        saveState(state);

        this.dispatch(LOAD_MERCHANT);
    },
    [SET_MERCHANT](state, {merchant}) {
        state.merchant = merchant;
    },
    [ERROR](state) {
        state.status = 'error';
    },
    [SET_HTML_CONTENT](state, html) {
        state.htmlContent = html;
        saveState(state);
    },
    [SET_RESET](state, {email, resetToken}) {
        if (email) state.email = email;
        if (resetToken) state.resetToken = resetToken;
    },
    [CLEAR_RESET](state)
    {
        state.email = null;
        state.resetToken = null;
    }
}