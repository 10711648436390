<template>
	<CCardBody>
		<CRow>
			<CCol>								
				<h1>Login</h1>
			</CCol>
		</CRow>
		<CRow>
			<CCol sm="7">								
				<p class="text-muted">Sign In to your account</p>
			</CCol>
			<CCol sm="3" >
				<strong class="move-left text-primary">OR</strong>
			</CCol>
		</CRow>
		<CRow>
			<CCol sm="7">
				<CForm @submit.prevent="submit">
					<CInput placeholder="Email" autocomplete="email"
							type="email"
							v-model="email"
							:disabled="loggingIn"
							:lazy="true"
							:value.sync="$v.form.email.$model"
							:isValid="checkIfValid('email')"
							invalidFeedback="This is a required field and must be valid e-mail address"
							style="height: 42px"
					>
						<template #prepend-content
							><CIcon name="cil-user"
						/></template>
					</CInput>
					<CInput
						placeholder="Password"
						type="password"
						autocomplete="curent-password"
						v-model="password"
						:disabled="loggingIn"
						:lazy="true"
						:value.sync="$v.form.password.$model"
						:isValid="checkIfValid('password')"
						invalidFeedback="This is a required field and must be valid password"
						style="height: 42px"
					>
						<template #prepend-content
							><CIcon name="cil-lock-locked"
						/></template>
					</CInput>   
					<div class="d-flex flex-row">
						<CInputCheckbox
							label="Remember me"
							custom
							class="mb-3"
							:checked.sync="rememberMe"
							:disabled="loggingIn"
						/>
						<CLink style="margin-left: auto" class="mr-1" to="password">Lost password?</CLink>   
					</div>                            
					<CButton color="primary" type="submit" :disabled="loggingIn"><CSpinner v-if="loggingIn" size="sm" class="mr-2"></CSpinner>Login</CButton>                                  											
				</CForm>
			</CCol>
			<CCol sm="3" class="border-secondary border-left">
				<GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="submitGoogle" :onFailure="googleFailed" disabled>Login</GoogleLogin>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<CAlert :show="true" color="danger" :fade="false" :class="['mt-3', 'mb-2', loginFailed ? 'visible' : 'invisible']">{{ loginError }}</CAlert>
			</CCol>
		</CRow>								
		<span class="version text-secondary mr-4 pr-2">v{{ appVersion }}{{devMode ? "dev":""}}</span>	
	</CCardBody>	
</template>

<script>
import { authAction } from '@/store/modules/auth';
import { mapGetters } from "vuex";
import { getResponseData, getResponseStatus } from "@/utils";
import { validationMixin } from "vuelidate"
import { required, email } from "vuelidate/lib/validators"

import GoogleLogin from 'vue-google-login';
import BASE_URL from '@/appConfig';

export default {
	components: {
		GoogleLogin
	},
	data() {
		return {
            form: this.getEmptyForm(),
			email: "",
			password: null,
            rememberMe: false,
			loggingIn: false,
			loginFailed: false,
			loginError: "!",
			params: {
				client_id: "695340794940-8r7natqv68r2bdqjjipq6fsoasbcriqm.apps.googleusercontent.com",
				redirect_uri: BASE_URL + "/api/auth/google"				
			},
			renderParams: {
				width: 240,
				height: 40,
				longtitle: true
			}
		};
	},
	computed: {
		...mapGetters(["isAuthenticated", "appVersion", "devMode"]),
        isValid () { return !this.$v.form.$invalid },
        isDirty () { return this.$v.form.$anyDirty },
	},    
	methods: {
        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName];
            if (!field.$dirty) {
                return null
            } 
			if (fieldName == 'password') {
				if (!field.$model || field.$model.length < 4) return false;
			}
            return !(field.$invalid || field.$model === '') ? null : false;            
        },
        validate () {
            this.$v.$touch()
        },
        getEmptyForm () {
            return {
                email: "",
                password: "",
                rememberMe: false
            }
        },
		/* eslint-disable */
		googleFailed(args){
			if (args && args.error) {
				switch (args.error) {
					case "popup_closed_by_user":
						this.loginFailed = false;
						break;

					case "access_denied":
						this.loginFailed = true;
						this.loginError = "Received 'Access denied' from Google. Failed to login with Google.";
						break;

					case "immediate_failed":
						this.loginFailed = true;
						this.loginError = "No Google account selected. Failed to login with Google."
						break;					

					default:
						this.loginFailed = true;
						this.loginError = "An unknown error occured. Failed to login with Google."
						break;
				}
			}
		},
		async submitGoogle(googleUser) {
			this.loggingIn = true;
			this.loginFailed = false;

			try {
				const id_token = googleUser.getAuthResponse().id_token;		
				await this.$store.dispatch(authAction.LOGIN_GOOGLE, { tokenId: id_token });
			} 
			catch (e) 
			{
				this.handleLoginError(e);
			}

			this.loggingIn = false;

			if (this.isAuthenticated) {
				this.$router.replace("/");
			}
		},
		async submit() {
			console.log('submit');
            if (!this.isValid) {
                return;
            }
			this.loggingIn = true;
			this.loginFailed = false;
			try {
				const { email, password, rememberMe } = this;                
				if (email && password) {
					await this.$store.dispatch(authAction.LOGIN, { email, password, rememberMe });
				}
			} catch (e) {
				this.handleLoginError(e);
			}
			this.loggingIn = false;

			if (this.isAuthenticated) {
				this.$router.replace("/");
			}
		},

		handleLoginError(e) {
			this.loginFailed = true;
			const data = getResponseData(e);
			const status = getResponseStatus(e);
			if (status == 500 || !data) {
				this.loginError = "An unknown error occurred. Please try again later.";
			}
			else if (data) {
				switch (data.errorCode) {
					case 2:
						this.loginError = "Too many failed attempts. Please try again later.";
						break;
					default:
						this.loginError = "Login failed";
						break;
				}
			}
		}
	},
	created() {
		// window.versionTimer?.reset();
		// this.$store.dispatch(authAction.CHECK_VERSION);
	},
    mixins: [validationMixin],
    validations: {
        form: {
            email: {
                required,
                email
            },
            password: {
                required
            },
            rememberMe: {

            }
        }
    }
};
</script>

<style>
.version {
	position: absolute; right: 12px; bottom: 6px;
	display: block;
}
.abcRioButtonContents {
	margin-left: -10px !important;
}
</style>

<style scoped>
.border-left {
	border-top: none !important;
	border-right: none !important;
	border-bottom: none !important;
}
.move-left {
	transform: translateX(-23px);
	display: block;
}
</style>