export class ToastMessage {
    static idCounter = 1;

    static Error(message) {
        return new ToastMessage(message, true);
    }

    get message() {
        return this._message;
    }
    get isError() {
        return this._isError;
    }
    get id() {
        return this._id
    }    

    constructor(message, isError=false) {
        this._message = message;
        this._isError = isError;
        this._id = ToastMessage.idCounter++;
    }
}