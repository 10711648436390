import { pages as pagesApi } from '@/api';
import { SET_PAGES, SET_PAGE_DATA, SET_TRANSLATION, /*SET_PAGE,*/ LOADING, SET_ERROR, SET_LANGUAGES } from './mutations';

export const LIST_PAGES = 'list-pages',
             LOAD_PAGE = 'get-page',
             SAVE_PAGE = 'save-page',
             CREATE_PAGE = 'create-page',
             DELETE_PAGE = 'del-page',
             REMOVE_TRANSLATION = 'rm-translation';

export const actionNames = Object.freeze({
    LIST_PAGES: 'content/' + LIST_PAGES,
    LOAD_PAGE: 'content/' + LOAD_PAGE,
    SAVE_PAGE: 'content/' + SAVE_PAGE,
    DELETE_PAGE: 'content/' + DELETE_PAGE,
    CREATE_PAGE: 'content/' + CREATE_PAGE,
    REMOVE_TRANSLATION: 'content/' + REMOVE_TRANSLATION
});

export default {
    async [LIST_PAGES]({rootGetters,commit}) {
        const merchantId = rootGetters.merchantId;

        commit(SET_PAGES, {pages: []});
        commit(LOADING, true);
        let response = null;
        try {
            response = await pagesApi.list({merchantId});
        }
        catch (e) {
            commit(LOADING, false);
            setTimeout(function() {
                commit(SET_ERROR);
            }, 50);
            throw e;
        }

        commit(LOADING, false);

        commit(SET_LANGUAGES, response.data.languages);

        const pages = response.data.pages;
        return commit(SET_PAGES, {pages});
    },
    async [LOAD_PAGE]({rootGetters,commit}, {name,language}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);
        commit(SET_PAGE_DATA, {page:null, translations:null});

        let response = null;
        try {
            response = await pagesApi.details({merchantId,name,language});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }
        commit(LOADING, false);

        const data = response.data;
        return commit(SET_PAGE_DATA, {page:data.page,translations:data.translations});
    },
    /* eslint-disable */
    async [SAVE_PAGE]({rootGetters,commit}, {name,language,title,content,kind}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);

        let response = null;
        try {
            response = await pagesApi.save({merchantId,name,language,title,content,kind});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }
        if (response.data != null) {
            commit(SET_TRANSLATION, response.data.language);
        }

        commit(LOADING, false);
    },
    /* eslint-disable */
    async [DELETE_PAGE]({rootGetters,commit}, {name}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);

        let response = null;
        try {
            response = await pagesApi.delete({merchantId,name});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }
        if (response.data != null) {
        }

        commit(LOADING, false);
        commit(SET_PAGE_DATA, {page:null, translations:null});
    },
    async [CREATE_PAGE]({rootGetters,commit}, {name, kind}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);

        let response = null;
        try {
            response = await pagesApi.create({merchantId,name,kind});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }
        if (response.data != null) {
            console.debug(response.data);
        }

        commit(LOADING, false);
    },
    async [REMOVE_TRANSLATION]({rootGetters,commit}, {name,language}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);

        let response = null;
        try {
            response = await pagesApi.removeTranslation({merchantId,name,language});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }
        commit(SET_TRANSLATION, response.data.language, true);

        commit(LOADING, false);
    }
}
