export const LOADING = 'loading';
export const SET_DATA = 'set-data';
export const SET_ERROR = 'set-error';

export default {  
    [LOADING](state, loading) {
        state.loading = loading;
    },
    [SET_DATA](state, data) {
        if (data == null) {
            state.settings = null;
            state.custom = null;
            state.automation = null;
        }
        else {
            state.settings = data.settings;
            state.custom = data.custom;
            state.automation = data.automation;
        }
    },
    [SET_ERROR](state) {
        state.hasError= true;    
    }
}