export default {
    loading(state) {
        return state.loading;
    },    
    hasError(state) {
        return state.hasError;
    },
    settings(state) {
        return state.settings;
    },
    custom(state) {
        return state.custom;
    },
    automation(state) {
        return state.automation;
    } 
}