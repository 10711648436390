export const LOADING = 'loading';
export const RESET = 'reset';
export const SET_ALL = 'set-all';
export const ERROR = 'error';
export const SET_LINKS = "set-links";

export default {  
    [LOADING](state, loading) {
        state.loading = loading;
    },
    [ERROR](state /*, error*/) {
        state.hasError = true;
    },
    [RESET](state) {
        state.hasError = false;    
        state.pages = [];    
        state.languages = [];    
        state.links = [];    
    },
    [SET_ALL](state, { languages, pages, links}) {
        state.pages = pages;    
        state.languages = languages;    
        state.links = links;    
    },
    [SET_LINKS](state, { links}) { 
        state.links = links;    
    }
}