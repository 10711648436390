import Vue from 'vue';

const isDevMode = Vue.config.devtools;

export default Object.freeze({
    BASE_URL: isDevMode ? "https://localhost:44338" : "https://reefee-eu-admin-api.azurewebsites.net"
});

let versionCheckInterval, rightsCheckInterval;

if (isDevMode) {
    // Development
    versionCheckInterval = 30 * 60 * 1000; // 10 minutes
    rightsCheckInterval = 2 * 60 * 1000; // 2 minutes
}
else {
    // Production
    versionCheckInterval = 10 * 60 * 1000; // 10 minutes
    rightsCheckInterval = 30 * 60 * 1000; // 30 minutes
}

export const INTERVAL_VERSION_CHECK = versionCheckInterval;
export const INTERVAL_RIGHTS_CHECK = rightsCheckInterval;
