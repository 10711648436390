export class VersionTimer 
{    
    constructor(callback, interval) {
        this.callback = callback;
        this.interval = interval;
        this.intervalId = null;
    }

    reset() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        this.intervalId = setInterval(this.callback, this.interval);
    }
}