import { products, bonuses, channels as channelsApi } from '@/api';
import { SET_PRODUCT, SET_PRODUCTS, SET_CHANNELS, SET_CHANNEL, SETTINGS, LOADING, SET_ERROR, PRODUCT_ACTIVE, BONUS_STATUS } from './mutations';

export const LOAD_PRODUCTS = 'loadProducts',
             LOAD_PRODUCTS_REWARDS = 'loadProductsRewards',
             LOAD_PRODUCTS_CHANNEL = 'loadProductsChannel',
             LOAD_PRODUCTS_TRANSLATION = 'loadProductsTranslation',
             LOAD_PRODUCT = 'loadProduct',
             LOAD_BONUSES = 'listBonuses',
             LOAD_BONUSES_TRANSLATION = 'listBonusesTranslation',
             LOAD_BONUS = 'loadBonus',
             SET_BONUS_AVAILABLE = 'setBonusAvailable',
             REMOVE_BONUS = 'remove-bonus',
             LOAD_CHANNELS = "loadChannels",
             LOAD_CHANNELS_UPLOAD = "loadChannels-upload",
             LOAD_CHANNEL = 'loadChannel',
             UPDATE_CHANNEL = 'updateChannel',
             SET_PRODUCT_ACTIVE = 'set-product-active',
             CREATE_BONUS = 'create-bonus',
             UPDATE_BONUS = 'update-bonus',
             UPDATE_PRODUCT = 'update-product'
;

export const actionNames = Object.freeze({
    LOAD_PRODUCTS: 'products/' + LOAD_PRODUCTS,
    LOAD_PRODUCTS_REWARDS: 'products/' + LOAD_PRODUCTS_REWARDS,
    LOAD_PRODUCTS_CHANNEL: 'products/' + LOAD_PRODUCTS_CHANNEL,
    LOAD_PRODUCTS_TRANSLATION: 'products/' + LOAD_PRODUCTS_TRANSLATION,
    LOAD_PRODUCT: 'products/' + LOAD_PRODUCT,
    SET_PRODUCT_ACTIVE: 'products/' + SET_PRODUCT_ACTIVE,
    UPDATE_PRODUCT: 'products/' + UPDATE_PRODUCT,

    LOAD_BONUSES: 'products/' + LOAD_BONUSES,
    LOAD_BONUSES_TRANSLATION: 'products/' + LOAD_BONUSES_TRANSLATION,
    LOAD_BONUS : 'products/' + LOAD_BONUS,
    SET_BONUS_AVAILABLE: 'products/' + SET_BONUS_AVAILABLE,
    CREATE_BONUS: 'products/' + CREATE_BONUS,
    UPDATE_BONUS: 'products/' + UPDATE_BONUS,
    REMOVE_BONUS: 'products/' + REMOVE_BONUS,

    LOAD_CHANNELS: 'products/' + LOAD_CHANNELS,
    LOAD_CHANNEL: 'products/' + LOAD_CHANNEL,
    LOAD_CHANNELS_UPLOAD: 'products/' + LOAD_CHANNELS_UPLOAD,
    UPDATE_CHANNEL: 'products/' + UPDATE_CHANNEL
});

const listProducts = async function({commit}, rqFnc) {
    commit(SET_PRODUCTS, {products: []});
    commit(LOADING, true);
    let response = null;
    try {
        response = await rqFnc();
    }
    catch (e) {
        commit(LOADING, false);
        setTimeout(function() {
            commit(SET_ERROR);
        }, 100);
        throw e;
    }

    commit(LOADING, false);

    if (response.data.settings) {
        commit(SETTINGS, response.data.settings);
    }

    const products = (response.data.products) ? response.data.products : response.data.bonuses;

    return commit(SET_PRODUCTS, {products: products, channel: response.data.channel});  
}

export default {    
    
    async [LOAD_PRODUCTS]({rootGetters,commit}) {
        const merchantId = rootGetters.merchantId;
        await listProducts({commit}, () => products.list({merchantId}));
    },
    async [LOAD_PRODUCTS_CHANNEL]({rootGetters,commit}, {channel}) {
        const args = {merchantId: rootGetters.merchantId, channel};
        await listProducts({commit}, () => products.listChannel(args));
    },    
    async [LOAD_PRODUCTS_TRANSLATION]({rootGetters,commit},{translation}) {
        const args = {merchantId: rootGetters.merchantId, translation};
        await listProducts({commit}, () => products.listTranslation(args));
    },
    async [LOAD_PRODUCTS_REWARDS]({rootGetters,commit}) {
        const merchantId = rootGetters.merchantId;
        await listProducts({commit}, () => products.listRewards({merchantId}));
    },
    async [LOAD_PRODUCT]({rootGetters,commit}, {sku}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);
        commit(SET_PRODUCT, {product: null });
        
        let response = null;
        try {
            response = await products.details({merchantId, sku});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }
    
        commit(LOADING, false);
        commit(SETTINGS, { bonusSkus: response.data.bonusSkus });
        return commit(SET_PRODUCT, {product: response.data.product /* TODO: bonusSKUs */ });  
    },
    async [LOAD_BONUSES]({rootGetters,commit}) {
        const merchantId = rootGetters.merchantId;
        await listProducts({commit}, () => bonuses.list({merchantId}));
    },
    async [LOAD_BONUSES_TRANSLATION]({rootGetters,commit},{translation}) {
        const args = {merchantId: rootGetters.merchantId, translation};
        await listProducts({commit}, () => bonuses.listTranslation(args));
    },
    async [LOAD_BONUS]({rootGetters,commit}, {sku}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);
        commit(SET_PRODUCT, {product: null });

        let response = null;
        try {
            response = await bonuses.details({merchantId, sku});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }
    
        commit(LOADING, false);
        return commit(SET_PRODUCT, {product: response.data });  
    },
    async [LOAD_CHANNELS]({rootGetters,commit})
    {
        const merchantId = rootGetters.merchantId;
        
        commit(SET_CHANNELS, {channels: []});
        commit(LOADING, true);
        let response = null;
        try {
            response = await channelsApi.list({merchantId});
        }
        catch (e) {
            commit(LOADING, false);
            setTimeout(function() {
                commit(SET_ERROR);
            }, 100);
            throw e;
        }

        commit(LOADING, false);

        const channels = response.data.channels;

        return commit(SET_CHANNELS, {channels});  
    },
    async [LOAD_CHANNEL]({rootGetters,commit}, {channel}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);
        commit(SET_CHANNEL, {channel: null });

        let response = null;
        try {
            response = await channelsApi.details({merchantId, channel});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }

        commit(LOADING, false);
        return commit(SET_CHANNEL, {channel: response.data });  
    },
    async [LOAD_CHANNELS_UPLOAD]({rootGetters,commit})
    {
        const merchantId = rootGetters.merchantId;
        
        commit(SET_CHANNELS, {channels: []});
        commit(LOADING, true);
        let response = null;
        try {
            response = await channelsApi.listUploadChannels({merchantId});
        }
        catch (e) {
            commit(LOADING, false);
            setTimeout(function() {
                commit(SET_ERROR);
            }, 100);
            throw e;
        }

        commit(LOADING, false);

        const channels = response.data.channels;

        return commit(SET_CHANNELS, {channels});  
    },
    async [UPDATE_CHANNEL]({rootGetters,commit}, {channel, review, general}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);

        let response = null;
        try {
            response = await channelsApi.update({merchantId, channel, data: { review, general }});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;
        }

        commit(LOADING, false);
        return commit(SET_CHANNEL, {channel: response.data });  
    },
    async [SET_PRODUCT_ACTIVE]({rootGetters,commit,getters}, {channel,isActive}) {        
        const merchantId = rootGetters.merchantId;
        const sku = getters.product.sku;
        commit(LOADING, true);

        let response = null;
        try {
            response = await products.setActive({merchantId, sku, channel, isActive});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;            
        }
        
        commit(LOADING, false);
        commit(PRODUCT_ACTIVE, response.data);
    },
    async [UPDATE_PRODUCT]({rootGetters,commit,getters}, {sku,translations,removeRewardSKU,addRewardSku}) {
        const merchantId = rootGetters.merchantId;
        if (!sku) sku = getters.product.sku;
        commit(LOADING, true);

        let response = null;
        try {
            response = await products.update({merchantId,sku,translations,removeRewardSKU,addRewardSku});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;            
        }
        
        commit(LOADING, false);
        commit(SET_PRODUCT, {product: response.data });
    },
    async [SET_BONUS_AVAILABLE]({rootGetters,commit,getters}, {isAvailable}) {
        const merchantId = rootGetters.merchantId;
        const sku = getters.product.sku;
        commit(LOADING, true);
        
        let response = null;
        try {
            response = await bonuses.setAvailable({merchantId,sku,isAvailable});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;            
        }
        
        commit(LOADING, false);
        commit(BONUS_STATUS, response.data);
    },
    async [UPDATE_BONUS]({rootGetters,commit,getters}, {sku,translations}) {
        const merchantId = rootGetters.merchantId;

        if (!sku) sku = getters.product.sku;
        commit(LOADING, true);

        let response = null;
        try {
            response = await bonuses.setTranslations({merchantId,sku,translations});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;            
        }
        
        commit(LOADING, false);
        commit(SET_PRODUCT, {product: response.data });
    },
    async [REMOVE_BONUS]({rootGetters,commit}, {sku}) {
        const merchantId = rootGetters.merchantId;
        commit(LOADING, true);
        
        try {
            await bonuses.remove({merchantId,sku});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;            
        }
        
        commit(LOADING, false);
    },
    async [CREATE_BONUS]({rootGetters,commit}, {sku,bonus}) {
        const merchantId = rootGetters.merchantId;

        commit(LOADING, true);
        
        try {
            await bonuses.create({merchantId,sku,bonus});
        }
        catch (e) {
            commit(LOADING, false);
            throw e;            
        }
        
        commit(LOADING, false);
    }
}