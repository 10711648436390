export const
    VIEW_DASHBOARD = 'view_dashboard',
    VIEW_SHIPMENT = 'view_shipment',
    EDIT_SHIPMENT = 'edit_shipment',
    EXPORT_SHIPMENT = 'export_shipment',
    VIEW_PRODUCT = 'view_product',
    EDIT_PRODUCT = 'edit_product',
    VIEW_REWARD = 'view_reward',
    EDIT_REWARD = 'edit_reward',
    VIEW_CONTENT = 'view_content',
    EDIT_CONTENT = 'edit_content',
    VIEW_CHANNEL = 'view_channel',
    EDIT_CHANNEL = 'edit_channel',
    VIEW_SETTINGS = 'view_settings',
    EDIT_SETTINGS = 'edit_settings',
    VIEW_USER = 'view_user',
    EDIT_USER = 'edit_user',
    VIEW_ROLES = 'viewr_oles',
    EDIT_ROLES = 'edit_roles',
    ADMIN = 'admin',
    SUPERADMIN = 'superadmin',
    VIEW_FEEDBACK = 'view_feedback',
    VIEW_INTERNAL = 'view_internal',
    VIEW_CONVERSION = 'view_conversion',
    EDIT_CONVERSION = 'edit_conversion',
    EDIT_HTML = 'edit_html'
;

export const mapPermissions = (m) => {
    const res = { };
    for (const [key, val] of Object.entries(m)) {
        res[key] = function() {
            const hasRight = this.$store.getters.hasRight;
            return hasRight(val);
        }
    }
    return res;
};
