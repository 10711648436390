import Vue from 'vue'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import store from './store'
import router from './router'
import { iconsSet as icons } from './assets/icons/icons.js'
import { VersionTimer } from '@/utils';
import { CHECK_VERSION, ACCESS } from '@/store/modules/auth/action-types';
import { INTERVAL_VERSION_CHECK, INTERVAL_RIGHTS_CHECK } from '@/appConfig'

Vue.use(CoreuiVuePro);
Vue.config.productionTip = false

new Vue({  
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: '<App/>',
  components: {
    App
  },
  beforeCreate() {
    
  },
  router,
})
.$mount('#app');

const checkVersion = () => {
  store.dispatch(CHECK_VERSION);
};

const checkAccess = () => {
  store.dispatch(ACCESS);
}

window.store = store;
window.router = router;
window.versionTimer = new VersionTimer(checkVersion, INTERVAL_VERSION_CHECK);
window.versionTimer.reset();

window.accessTimer = new VersionTimer(checkAccess, INTERVAL_RIGHTS_CHECK);
window.accessTimer.reset();

setTimeout(checkVersion);