import { feedbacks } from '@/api'
export const LOADING = 'loading';
export const SETTINGS = 'settings';
export const LOAD_SETTINGS = 'load-settings';

export default {
    namespaced: true,
    state() {
        return {
            loading: false,
            channelOptions: [],          
            skuOptions: []
        }
    },
    mutations: {
        [LOADING](state, loading) {
            state.loading = loading;
        },
        [SETTINGS](state, {channels,skus}) {
            state.channelOptions = channels;
            state.skuOptions = skus;
        }
    },
    actions: {        
        async [LOAD_SETTINGS]({rootGetters,commit}) {            
            const merchantId = rootGetters.merchantId;
            commit(LOADING, true);

            let response = null;
            try {
                response = await feedbacks.loadSettings({merchantId});
            }
            catch {
                commit(LOADING, false);
                return;
            }
            commit(LOADING, false);
            commit(SETTINGS, response.data);
        }
    },
    getters: {
        loading: (state) => state.loading,
        channelOptions: (state) => state.channelOptions,
        skuOptions: (state) => state.skuOptions
    }
}