import { shipments } from '@/api'

export const LOADING = 'loading';
export const SETTINGS = 'settings';
export const LOAD_SETTINGS = 'load-settings';

export default {
    namespaced: true,
    state() {
        return {
            loading: false,
            channels: [],
            bonuses: [],
            countries: []           
        }
    },
    mutations: {
        [LOADING](state, loading) {
            state.loading = loading;
        },
        [SETTINGS](state, {channels,bonuses,countries}) {
            state.channels = channels;
            state.bonuses = bonuses;
            state.countries = countries;
        }
    },
    actions: {        
        async [LOAD_SETTINGS]({rootGetters,commit}) {            
            const merchantId = rootGetters.merchantId;
            commit(LOADING, true);

            let response = null;
            try {
                response = await shipments.loadSettings({merchantId});
            }
            catch {
                commit(LOADING, false);
                return;
            }
            commit(LOADING, false);
            commit(SETTINGS, response.data);
        }
    },
    getters: {
        loading: (state) => state.loading,
        channels: (state) => state.channels,
        bonuses: (state) => state.bonuses,
        countries: (state) => state.countries,
    }
};
