export default {
    loading(state) {
        return state.loading;
    },    
    hasError(state) {
        return state.hasError;
    },
    pages(state) {
        return state.pages;
    },
    page(state) {
        return state.page;
    },
    translations(state) {
        return state.translations;
    },
    languages(state){
        return state.languages;
    }
}