import getters from './getters.js';
import actionTypes from './action-types';
import mutations from './mutations';
import actions from './actions';

export const authAction = {
    ...actionTypes
};

export default {
    namespaced: false,
    state() {
        return {
            email: null,
            resetToken: null,
            status: '',
            merchantId: null,
            merchant: null,
            sites: [],
            token: null,
            tokenExpiration: null,
            refreshExpiration: null,
            loggingOut: false,
            profilePicture: null,
            username: null,
            htmlContent: null,
            timeoutId: 0
        }
    },
    mutations,
    actions,
    getters
};