import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth';
import productModule from './modules/products';
import contentModule from './modules/content';
import linksModule from './modules/links';
import generalModule from './modules/general'
import shipmentsModule from './modules/shipments'
import reportsModule from './modules/reports'

//import { INIT } from './modules/auth/mutation-types';
import { INIT } from './modules/auth/action-types';
import { actionNames } from './modules/products/actions';
import { ToastMessage } from '@/common';

export const PUSH_ERROR = 'pushError';
export const PUSH_SUCCESS = 'pushSuccess';

export const productActions = actionNames;

Vue.use(Vuex);

const isDevMode = !!Vue.config.devtools;

const state = {
  packageVersion: process.env.PACKAGE_VERSION || '0',
  devMode: isDevMode,
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  toasts: []
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  [PUSH_ERROR](state, {message}) {
    const toast = ToastMessage.Error(message);
    state.toasts.push(toast);
    setTimeout(() => store.commit('removeError', {id:toast.id}), 5000);
  },
  [PUSH_SUCCESS](state, {message}) {
    const toast = new ToastMessage(message);
    state.toasts.push(toast);
    setTimeout(() => store.commit('removeError', {id:toast.id}), 5000);
  },
  removeError(state, {id}) {
    const idx = state.toasts.map(item => item.id).indexOf(id);
    if (idx >= 0) {
      state.toasts.splice(idx, 1);
    }    
  }
}

const getters = {
  toasts(state) {
    return state.toasts;
  },
  appVersion: (state) => state.packageVersion,
  devMode: (state) => state.devMode
};

const modules = {
  auth: authModule,
  products: productModule,
  content: contentModule,
  links: linksModule,
  general: generalModule,
  shipments: shipmentsModule,
  reports: reportsModule
}

const store = new Vuex.Store({
  state,
  mutations,
  modules,
  getters
});

store.dispatch(INIT);

export default store;

window.store = store;