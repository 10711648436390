import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/auth/Login'
import PasswordReset from '@/views/auth/PasswordReset'
import store from '@/store';

import { authAction } from '@/store/modules/auth';
import { VIEW_SETTINGS, VIEW_CONTENT, VIEW_SHIPMENT, VIEW_PRODUCT, EDIT_PRODUCT, VIEW_REWARD, VIEW_CHANNEL, VIEW_DASHBOARD, VIEW_FEEDBACK } from '@/permissions';

const TheContainer = () => import('@/containers/TheContainer')
const AuthPage = () => import('@/containers/AuthPage')

Vue.use(Router)

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage,
    children: [
      {
        name: 'Login',
        path: '/login',
        component: Login,
        meta: { anonymous: true }
      },
      {
        path: '/password',
        name: 'LostPassword',
        component: PasswordReset,
        meta: { anonymous: true },
        props: route => ( { mail: route.query["email"] } )
      },
      {
        path: '/verify',
        name: 'Verify',
        component: () => import('@/views/auth/Verify'),
        meta: { anonymous: true },
        props: route => ({ loginLink: route.query["login"] })
      },
      {
        path: '/set-password',
        name: 'set-password',
        component: () => import('@/views/auth/NewPassword'),
        meta: { anonymous: true },
        props: route => ({ loginLink: route.query["login"] })
      },
      {
        path: '/password-changed',
        name: 'password-changed',
        component: () => import('@/views/auth/PasswordChanged'),
        meta: { anonymous: true }
      },
    ]
  },
  {
    path: '/',
    name: 'Home',
    component: TheContainer,
    children: [
      {
        name: 'General',
        path: 'general',
        redirect: '/general/',
        component: () => import('@/containers/GeneralContainer'),// GeneralContainer,
        children: [
          {
            path: '',
            name: 'GeneralSettings',
            meta: { label: 'General Settings', activeClass: 'automation-page', rights: [ VIEW_SETTINGS ] },
            component: () => import('@/views/general/Settings') // GeneralSettings
          },
          {
            path: 'custom',
            name: 'Customizations',
            meta: { label: 'Design & Customization', activeClass: 'design-page', rights: [ VIEW_CONTENT ] },
            component: () => import('@/views/general/Customizations') // Customizations
          }
        ]
      },
      {
        name: 'Shipments',
        path: 'shipments',
        component: () => import('@/views/entries/ShipmentList'), // ShipmentList,
        meta: { activeClass: 'shipments-page', rights: [ VIEW_SHIPMENT ] }
      },
      {
        name: 'Feedbacks',
        path: 'feedbacks',
        component: () => import ('@/views/reports/Feedback.vue'),
        meta: { activeClass: 'feedback-page', rights: [ VIEW_FEEDBACK ] }
      },
      {
        name: 'Products',
        path: 'products',
        redirect: '/products/',
        component: () => import('@/containers/ProductContainer'), // ProductContainer,
        meta: { activeClass: 'products-page', rights: [ VIEW_PRODUCT ] },
        children: [
          {
            path: 'channel/:channel',
            name: 'ProductsChannelSettings',
            meta: { label: 'Channel Settings', rights: [ VIEW_PRODUCT ] },
            component: () => import('@/views/products/ProductsChannelList'), // ProductsChannelList,
            props: true
          },
          {
            path: 'translation/:translation',
            name: 'ProductsTranslationSettings',
            meta: { label: 'Translations', rights: [ VIEW_PRODUCT ] },
            component: () => import('@/views/products/ProductsTranslationList'),// ProductsTranslationList,
            props: true
          },
          {
            path: 'rewards',
            name: 'ProductsRewardSettings',
            meta: { label: 'Rewards', rights: [ VIEW_PRODUCT ] },
            component: () => import('@/views/products/ProductsRewardList'), // ProductsRewardList,
            props: true
          },
          {
            path: 'view/:sku',
            name: 'ProductDetails',
            meta: { label: 'Product Details', rights: [ VIEW_PRODUCT ] },
            component: () => import('@/views/products/ProductDetails'), // ProductDetails,
            props: route => ({
              sku: route.params.sku,
              channel: route.query.channel,
              translation: route.query.tl,
              rewards: route.query.rewards
            })
          },
          {
            path: 'upload',
            name: 'ProductUpload',
            meta: { label: 'Product Upload', activeClass: 'uploads-page', rights: [ EDIT_PRODUCT ] },
            component: () => import('@/views/products/ProductUpload'), // ProductUpload,
            props: true
          },
          {
            path: '',
            name: 'ProductsSettings',
            meta: { label: 'Overview', rights: [ VIEW_PRODUCT ] },
            component: () => import('@/views/products/ProductsList') // ProductsList
          }
        ]
      },
      {
        path: 'bonuses',
        redirect: '/bonuses/',
        name: 'Bonuses',
        component: () => import('@/containers/ChannelContainer'), // ChannelContainer,
        meta: { activeClass: "rewards-page", rights: [ VIEW_REWARD ] },
        children: [
          {
            path: 'translation/:translation',
            name: 'BonusTranslationSettings',
            meta: { label: 'Translations', rights: [ VIEW_REWARD ] },
            component: () => import('@/views/bonuses/BonusList'), // BonusList,
            props: true
          },
          {
            path: '',
            name: 'BonusSettings',
            meta: { label: 'Overview', rights: [ VIEW_REWARD ] },
            component: () => import('@/views/bonuses/BonusList'), // BonusList
          },
          {
            path: 'view/:sku',
            name: 'BonusDetails',
            meta: { label: 'Bonus Details', rights: [ VIEW_REWARD ] },
            component: () => import('@/views/bonuses/BonusDetails'), // BonusDetails,
            props: route => ({
              sku: route.params.sku,
              translation: route.query.tl
            })
          }
        ]
      },
            {
        path: 'channels',
        redirect: '/channels/',
        name: 'Channels',
        component: () => import('@/containers/ProductContainer'), // ProductContainer,
        meta: { activeClass: 'channels-page', rights: [ VIEW_CHANNEL ] },
        children: [
          {
            path: '',
            name: 'ChannelSettings',
            meta: { label: 'Overview', rights: [ VIEW_CHANNEL ] },
            component: () => import('@/views/channels/ChannelList'), // ChannelList,
            activeClass: 'channels-page'
          },
          {
            path: ':channelName',
            name: 'ChannelDetails',
            meta: { label: 'Channel Details', rights: [ VIEW_CHANNEL ] },
            component: () => import('@/views/channels/ChannelDetails'), //  ChannelDetails,
            props: true,
            activeClass: 'channels-page'
          }
        ]
      },
      {
        path: 'pages',
        redirect: '/pages/',
        name: 'Pages',
        component: () => import('@/containers/PagesContainer'), // PagesContainer,
        meta: { label: 'Overview', activeClass: 'content-page', rights: [ VIEW_CONTENT ] },
        children: [
          {
            path: '',
            name: 'PageList',
            meta: { label: 'Overview', rights: [ VIEW_CONTENT ] },
            component: () => import('@/views/content/ContentPageList') // ContentPageList
          },
          {
            path: ':pageName',
            name: 'Content Page',
            meta: { label: 'Page', rights: [ VIEW_CONTENT ] },
            component: () => import('@/views/content/ContentPage'), // ContentPage,
            props: route => ({
              pageName: route.params.pageName,
              lang: route.query.tl,
              kind: route.query.kind
            })
          }
        ]
      },
      {
        path: 'links',
        name: 'Links',
        component: () => import('@/views/content/LinkList'), // LinkList,
        meta: { label: 'Page', activeClass: 'content-links', rights: [ VIEW_CONTENT ] }
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Dashboard'), // Dashboard,
        meta: { label: 'Dashboard', activeClass: 'dashboard-page', rights: [ VIEW_DASHBOARD ] }
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/auth/Profile') // Profile
      },
      {
        path: '',
        name: 'Home',
        meta: { label: 'Start' },
        component: () => import( '@/views/Home') // Home
      }
    ]
  },
  { name: 'Logout', path: '/logout' },
  {
    path: '*',
    component: () => import('@/views/NotFound'), // NotFound,
    meta: { anonymous: true }
  }
]

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  if (to.name != "Login" && !(store.getters.isAuthenticated || to.meta.anonymous)) {
    return next({path:'/login', replace: true});
  }
  if (to.name == "Login" && store.getters.isAuthenticated) {
      // Login but still authenticated -> Logout
      store.dispatch(authAction.LOGOUT);
      return next(false);
  }
  if (to.name == "Logout" && store.getters.isAuthenticated) {
      // Logout and authenticated -> ensure logout happens
      store.dispatch(authAction.LOGOUT);
      return next(false);
  }
  if (to.name != "Home" && !store.getters.merchantId && !to.meta.anonymous) {
    return next('/');
  }

  const isAuthorized = !to.meta.rights || to.meta.rights.some(r => store.getters.hasRight(r) );
  if (!isAuthorized) {
    return next('/');
  }

  next();
});

router.afterEach((/*to,from*/) => {
  window.versionTimer?.reset();
})

export default router;
